

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'client_';

export default {
  path: routePre + '/client',
  name: 'client',
  header: 'client',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['client-index'],
	    title: '我的客户',
	  },
	  component: () => import('@/pages/client/index'),
	},
	
	{
	  path: 'add',
	  name: `${pre}add`,
	  meta: {
	    auth: ['client-add'],
	    title: '添加客户',
		activeMenu: routePre + '/client/index',
	  },
	  component: () => import('@/pages/client/add'),
	},
	
  ],
};
