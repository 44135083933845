import { render, staticRenderFns } from "./userInfo.vue?vue&type=template&id=a25c245e&scoped=true"
import script from "./userInfo.vue?vue&type=script&lang=js"
export * from "./userInfo.vue?vue&type=script&lang=js"
import style0 from "./userInfo.vue?vue&type=style&index=0&id=a25c245e&prod&scoped=true&styles=scss&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a25c245e",
  null
  
)

export default component.exports