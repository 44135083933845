

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'school_';

export default {
  path: routePre + '/school',
  name: 'school',
  header: 'school',
  redirect: {
    name: `${pre}student`,
  },
  component: LayoutMain,
  children: [
	
	
	{
	  path: 'student',
	  name: `${pre}student`,
	  meta: {
	    auth: ['school-student'],
	    title: '学员管理',
	  },
	  component: () => import('@/pages/school/student/index'),
	},
	
	{
	  path: 'student/create',
	  name: `${pre}studentCreate`,
	  meta: {
	    auth: ['school-student'],
	    title: '添加学员',
		activeMenu: routePre + '/school/student',
	  },
	  component: () => import('@/pages/school/student/create'),
	},
	
	{
	  path: 'course',
	  name: `${pre}course`,
	  meta: {
	    auth: ['school-course'],
	    title: '课程管理',
	  },
	  component: () => import('@/pages/school/course/index'),
	},
	
	{
	  path: 'video',
	  name: `${pre}video`,
	  meta: {
	    auth: ['school-video'],
	    title: '视频库',
	  },
	  component: () => import('@/pages/school/video/index'),
	},
	
	
	{
	  path: 'video/upload',
	  name: `${pre}videoUpload`,
	  meta: {
	    auth: ['school-video'],
	    title: '上传视频',
		activeMenu: routePre + '/school/video',
		hiddenMenu:true
	  },
	  component: () => import('@/pages/school/video/upload'),
	},
	
	{
	  path: 'teacher',
	  name: `${pre}teacher`,
	  meta: {
	    auth: ['school-teacher'],
	    title: '学管管理',
	  },
	  component: () => import('@/pages/school/teacher/index'),
	},
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['school-index'],
	    title: '控制台',
	  },
	  component: () => import('@/pages/school/index/index'),
	},
	
	
	
	{
	  path: 'index/traffic',
	  name: `${pre}indexTraffic`,
	  meta: {
	    auth: ['school-index'],
	    title: '流量明细',
		activeMenu: routePre + '/school/index',
	  },
	  component: () => import('@/pages/school/index/traffic'),
	},
	
	{
	  path: 'index/upgrade',
	  name: `${pre}indexUpgrade`,
	  meta: {
	    auth: ['school-index'],
	    title: '升级套餐',
		activeMenu: routePre + '/school/index',
	  },
	  component: () => import('@/pages/school/index/upgrade'),
	},
	{
	  path: 'view',
	  name: `${pre}view`,
	  meta: {
	    auth: ['school-view'],
	    title: '听课记录',
	  },
	  component: () => import('@/pages/school/view/index'),
	},
  ],
};
