

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'system_';

export default {
  path: routePre + '/system',
  name: 'system',
  header: 'system',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['system-index'],
	    title: '主体信息',
	  },
	  component: () => import('@/pages/system/index/index'),
	},
	{
	  path: 'update',
	  name: `${pre}update`,
	  meta: {
	    auth: ['system-index'],
	    title: '修改主体信息',
		activeMenu: routePre + '/system/index',
	  },
	  component: () => import('@/pages/system/index/update'),
	},
	
	{
	  path: 'setting',
	  name: `${pre}setting`,
	  meta: {
	    auth: ['system-setting'],
	    title: '系统配置',
	  },
	  component: () => import('@/pages/system/setting/index'),
	},
	
	
	{
	  path: 'product',
	  name: `${pre}systemProduct`,
	  meta: {
	    auth: ['system-product'],
	    title: '商品管理',
	  },
	  component: () => import('@/pages/system/product/index'),
	},
	
	
	{
	  path: 'product/add',
	  name: `${pre}addProduct`,
	  meta: {
	    auth: ['system-product'],
	    title: '编辑商品',
		activeMenu: routePre + '/system/product',
	  },
	  component: () => import('@/pages/system/product/add'),
	},
	
	{
	  path: 'paytype',
	  name: `${pre}systemPaytype`,
	  meta: {
	    auth: ['system-paytype'],
	    title: '收款方式',
	  },
	  component: () => import('@/pages/system/paytype/index'),
	},
	
	
  ],
};
