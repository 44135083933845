

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'tools_';

export default {
  path: routePre + '/tools',
  name: 'tools',
  header: 'tools',
  redirect: {
    name: `${pre}toolsQuote`,
  },
  component: LayoutMain,
  children: [
	
	
	{
	  path: 'quote',
	  name: `${pre}toolsQuote`,
	  meta: {
	    auth: ['tools-quote'],
	    title: '名额申请表',
	  },
	  component: () => import('@/pages/tools/quote/index'),
	},
	
	{
	  path: 'work',
	  name: `${pre}toolsWork`,
	  meta: {
	    auth: ['tools-work'],
	    title: '兼职表',
	  },
	  component: () => import('@/pages/tools/work/index'),
	},
	
	
  ],
};
