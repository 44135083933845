

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'spread_';

export default {
  path: routePre + '/spread',
  name: 'spread',
  header: 'spread',
  redirect: {
    name: `${pre}spread`,
  },
  component: LayoutMain,
  children: [
	
	
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['spread-index'],
	    title: '推广公司',
	  },
	  component: () => import('@/pages/spread/index/index'),
	},
	
	{
	  path: 'group',
	  name: `${pre}group`,
	  meta: {
	    auth: ['spread-group'],
	    title: '接量组',
	  },
	  component: () => import('@/pages/spread/group/index'),
	},
  ],
};
