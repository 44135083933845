
import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'home_';

export default {
  path: routePre + '/home',
  name: 'home',
  header: 'home',
  redirect: {
    name: `${pre}index`,
  },
  meta,
  component: LayoutMain,
  children: [
    {
      path: routePre + '/index',
      name: `${pre}index`,
      header: 'home',
      meta: {
        title: '控制台',
      },
      component: () => import('@/pages/index/index'),
    },
	
	{
	  path: routePre + '/recharge',
	  name: `${pre}recharge`,
	  header: 'home',
	  meta: {
	    title: '充值续费',
		activeMenu: routePre + '/index',
	  },
	  component: () => import('@/pages/index/recharge'),
	},
	
	{
	  path: routePre + '/invoice',
	  name: `${pre}invoice`,
	  header: 'home',
	  meta: {
	    title: '我的发票',
		activeMenu: routePre + '/index',
	  },
	  component: () => import('@/pages/index/invoice'),
	},
  ],
};
