

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'statistics_';

export default {
  path: routePre + '/statistics',
  name: 'statistics',
  header: 'statistics',
  redirect: {
    name: `${pre}statisticsClient`,
  },
  component: LayoutMain,
  children: [
	
	{
	  path: 'client/:type?',
	  name: `${pre}statisticsClient`,
	  meta: {
	    auth: ['statistics-client'],
	    title: '入量趋势',
	  },
	  component: () => import('@/pages/statistics/client/index'),
	},
	
	
	{
	  path: 'achievement/:type?',
	  name: `${pre}statisticsAchievement`,
	  meta: {
	    auth: ['statistics-achievement'],
	    title: '业绩统计',
	  },
	  component: () => import('@/pages/statistics/achievement/index'),
	},
	
	
  ],
};
