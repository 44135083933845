

import LayoutMain from '@/layout';
import setting from '@/setting';
let routePre = setting.routePre;

const meta = {
  auth: true,
};

const pre = 'contract_';

export default {
  path: routePre + '/contract',
  name: 'contract',
  header: 'contract',
  redirect: {
    name: `${pre}index`,
  },
  component: LayoutMain,
  children: [
	
	
	
	{
	  path: 'index',
	  name: `${pre}index`,
	  meta: {
	    auth: ['contract-index'],
	    title: '合同管理',
	  },
	  component: () => import('@/pages/contract/index'),
	},
	
	
	{
	  path: 'template',
	  name: `${pre}template`,
	  meta: {
	    auth: ['contract-template'],
	    title: '合同模版',
	  },
	  component: () => import('@/pages/contract/template/index'),
	},
	
	{
	  path: 'template/update',
	  name: `${pre}templateUpdate`,
	  meta: {
	    auth: ['setting-contract_template-create'],
	    title: '编辑模版',
		activeMenu: routePre + '/contract/template',
	  },
	  component: () => import('@/pages/contract/template/update'),
	},
	
	{
	  path: 'template/select',
	  name: `${pre}templateSelect`,
	  meta: {
	    auth: ['contract-index'],
	    title: '选择模版',
		activeMenu: routePre + '/contract/index',
	  },
	  component: () => import('@/pages/contract/template/select'),
	},
	
  ],
};
